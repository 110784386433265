import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from '../common/mediaQueries';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { zipCodeModalAtom } from '../../atom/zipCodeModalAtom';
import { Leaderboard } from '../dashboard/leaderboard/Leaderboard';

const Wrapper = styled.div`
  display: grid;
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%),
    url(/heroBackground.png),
    lightgray 50% / cover no-repeat;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  padding: 10px;
  width: 100dvw;

  ${MEDIA_QUERIES.laptop(css`
    padding: 0;
    grid-row: 2/3;
  `)}

  ${MEDIA_QUERIES.tablet(css`
    padding: 0 20px;
    grid-row: 2/3;
    /* max-width: calc(100vw - 100px); */
    gap: 20px;
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    grid-template-columns: 1fr;
    /* max-width: calc(100vw - 40px); */
    padding: 0 20px;
    gap: 30px;
  `)}
`;

const TextWrapper = styled.div`
  align-items: center;
  color: white;

  ${MEDIA_QUERIES.laptop(css`
    align-items: center;
    grid-column: 1/3;
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    grid-column: 1/2;
    gap: 10px;
  `)}
`;

const Heading = styled.div`
  color: var(--white, #fff);
  text-align: center;
  font-family: Droid Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 25px 0;

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 28px;
    text-align: left;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 26px;
    text-align: left;
  `)}
`;

const Content = styled.div`
  color: var(--white, #fff);
  text-align: center;
  font-family: Droid Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  max-width: 700px;
  margin: 0 auto;
  & p {
    margin-bottom: 20px;
    line-height: 1.6;
  }

  ${MEDIA_QUERIES.smallTablet(css`
    text-align: left;
    font-size: 12px;
  `)}
`;

const TakeActionTextStyle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  animation-name: color-change;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  cursor: pointer;

  @keyframes color-change {
    0% {
      color: red;
    }
    50% {
      color: white;
    }
    100% {
      color: red;
    }
  }
`;

export const TopPart = () => {
  const setZipCodeModal = useSetRecoilState(zipCodeModalAtom);

  return (
    <Wrapper>
      <TextWrapper>
        <Heading>Got a Minute?</Heading>
        <Content>
          <p>
            There are still 100 hostages in Gaza; Elderly men and women,
            fathers, young women, disabled, and chronically ill.
          </p>
          <p>
            According to testimonials from released captives, the hostages are
            kept without medication, are food deprived, have no access to
            showers, are forced to watch horrific documentation of the October
            7th massacre repeatedly, and are constantly assaulted by their
            captors.
          </p>
          <p>
            Every second that passes could be their last. They must be released
            immediately
          </p>
          <TakeActionTextStyle
            onClick={() =>
              setZipCodeModal((old) => ({ ...old, showZipModal: true }))
            }
          >
            Take a minute of your day <br />
            and make a call and save their lives.
          </TakeActionTextStyle>
        </Content>
      </TextWrapper>
    </Wrapper>
  );
};
